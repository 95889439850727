import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { ThemeProvider } from "react-bootstrap";
import { Provider, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

/* REEDUX STORE */
import store from "../redux/store";

/* PAGES */
import DashboardLayout from "../layout/index";
import Login from "../pages/auth/login";
import Dashboard from "../pages/dashboard/index";
import Contacts from "../pages/dashboard/contacts";


import Blogs from "../pages/dashboard/blogs";
import BlogDetail from "../pages/dashboard/blogs/blog-detail";
import UserDetails from "../pages/dashboard/user-details";
import Users from "../pages/dashboard/users/new-index";


/* STYLES */
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/global.scss";
import "react-toastify/dist/ReactToastify.css";
import Newsletter from "../pages/dashboard/newsletter";


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  const isLoggedIn = useSelector((state) => state.user.loggedIn);
  // console.log(isLoggedIn);
  return (
    <Provider store={store}>
      <ThemeProvider breakpoints={["xl", "lg", "md", "sm"]} minBreakpoint="sm">
        <ToastContainer />
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Login />} />
          </Routes>

          {/* DASHBOARD ROUTES */}
          {isLoggedIn && (
            <Routes>
              <Route
                path="/dashboard"
                element={
                  <DashboardLayout>
                    <Dashboard />
                  </DashboardLayout>
                }
              />
              <Route
                path="/contacts"
                element={
                  <DashboardLayout>
                    <Contacts />
                  </DashboardLayout>
                }
              />
       
              
              <Route
                path="/blogs"
                element={
                  <DashboardLayout>
                    <Blogs />
                  </DashboardLayout>
                }
              />
              <Route
                path="/blog-detail"
                element={
                  <DashboardLayout>
                    <BlogDetail />
                  </DashboardLayout>
                }
              />
              <Route
                path="/user-details"
                element={
                  <DashboardLayout>
                    <UserDetails />
                  </DashboardLayout>
                }
              />
              <Route
                path="/users"
                element={
                  <DashboardLayout>
                    <Users />
                  </DashboardLayout>
                }
              />
              <Route
                path="/newsletter"
                element={
                  <DashboardLayout>
                    <Newsletter />
                  </DashboardLayout>
                }
              />
          
          
            </Routes>
          )}
        </Router>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
