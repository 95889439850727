import React from "react";

const DashboardFooter = () => {
  return (
    <footer className="auth-footer">
      <div className="container">
        <div className="footer-options">
          <p>Dr. Vivek Kakkad © 2024. All rights reserved.</p>
          <div className="page-links">
            <p>
              Powered by{" "}
              <a
                href="https://www.roundtechsquare.com"
                style={{ color: "#8094AE" }}
              >
                RoundTechSquare
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default DashboardFooter;
